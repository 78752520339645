import React from 'react';

import { AboutSection } from './AboutSection';
import { DemoSection } from './DemoSection';
import { IntroSection } from './IntroSection';
import { WhatDoYouGetSection } from './WhatDoYouGetSection';
import { AboutUsSection } from './AboutUsSection';
import { SystemFeatures } from './SystemFeatures';
import { ProcessSection } from './ProcessSection';

export const IndexView = () => {
    return (
        <>
            <IntroSection />
            <WhatDoYouGetSection />
            <SystemFeatures />
            <AboutSection />
            <ProcessSection />
            <AboutUsSection />
            <DemoSection />
        </>
    );
};
