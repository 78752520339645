import React, { ReactNode } from 'react';
import styled from 'styled-components';

import colors from 'constants/styles/colors';
import media from 'constants/styles/media';

const SImageBoxWrapper = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const SImageBox = styled.div`
    background: ${colors.white};
    box-shadow: 0px 6px 18px #0000001a;
    border-radius: 12px;
    overflow: hidden;
`;

const SUpperImageBox1 = styled(SImageBox)`
    width: 600px;
    height: 375px;
    z-index: 1;
    position: relative;

    @media (max-width: ${media.desktop}) {
        width: 100%;
        height: 214px;
    }
`;

const SLowerImageBox1 = styled(SImageBox)`
    width: 414px;
    height: 317px;
    position: relative;
    top: -200px;
    right: 50px;
    z-index: 2;

    @media (max-width: ${media.desktop}) {
        width: 240px;
        height: 150px;
        top: -80px;
        right: 40px;
    }
`;

const SImageBox2 = styled(SImageBox)`
    width: 460px;
    height: 337px;
    margin-bottom: 142px;

    @media (max-width: ${media.desktop}) {
        width: 75%;
        height: 160px;
        margin-bottom: 140px;
    }
`;

const SUpperImageBox2 = styled(SImageBox2)`
    z-index: 1;
    margin-right: 150px;

    @media (max-width: ${media.desktop}) {
        margin-right: auto;
        right: 0;
    }
`;

const SLowerImageBox2 = styled(SImageBox2)`
    position: absolute;
    top: 142px;
    left: 150px;
    z-index: 2;
    height: 287px;

    @media (max-width: ${media.desktop}) {
        top: 80px;
        left: 70px;
        height: 160px;
    }
`;

const SLowerImageBox3 = styled(SImageBox)`
    width: 274px;
    height: 372px;
    right: 60px;
    position: relative;
    top: -260px;
    z-index: 2;

    @media (max-width: ${media.desktop}) {
        width: 205.5px;
        height: 279px;
        top: -120px;
        right: 20px;
        margin-bottom: -60px;
    }
`;

export enum ImageBoxLayout {
    Version1 = 'version1',
    Version2 = 'version2',
    Version3 = 'version3',
}

interface Props {
    layout?: ImageBoxLayout;
    className?: string;
    upperImage?: ReactNode;
    lowerImage?: ReactNode;
}

export const ImageBoxes = ({
    layout = ImageBoxLayout.Version1,
    className,
    upperImage,
    lowerImage,
}: Props) => {
    const imageBoxMap = {
        [ImageBoxLayout.Version1]: {
            upper: SUpperImageBox1,
            lower: SLowerImageBox1,
        },
        [ImageBoxLayout.Version2]: {
            upper: SUpperImageBox2,
            lower: SLowerImageBox2,
        },
        [ImageBoxLayout.Version3]: {
            upper: SUpperImageBox1,
            lower: SLowerImageBox3,
        },
    };

    const { upper: UpperImageBox, lower: LowerImageBox } = imageBoxMap[layout];

    return (
        <SImageBoxWrapper className={className}>
            <UpperImageBox>{upperImage}</UpperImageBox>
            <LowerImageBox>{lowerImage}</LowerImageBox>
        </SImageBoxWrapper>
    );
};
