import React from 'react';
import styled from 'styled-components';
import { Link } from 'src/components/link';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import media from 'constants/styles/media';
import { Chip } from '../../components/chip/index';
import { useStaticQuery, graphql } from 'gatsby';
import { APP_URL } from 'constants/index';

const SIntroSection = styled.section`
    width: 100%;
    height: 600px;
    margin-top: 100px;
    border: solid 3px white;
    display: flex;
    justify-content: space-between;
    position: relative;

    @media (max-width: ${media.tablet}) {
        height: 1200px;
        flex-direction: column;
    }
`;

const STextWrapper = styled.div`
    max-width: 530px;
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

const STextWrapperMotto = styled.p`
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 3.6px;
    color: #eebf4f;
    text-transform: uppercase;
    margin-bottom: 20px;
`;

const STextWrapperHeader = styled.h1`
    font-size: 64px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #363639;
    opacity: 1;
    margin-bottom: 30px;
`;

const STextWrapperDescription = styled.p`
    max-width: 395px;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 24px;
    color: #96969d;
    margin-bottom: 52px;
`;

const SImageWrapper = styled.div`
    flex: 1;
    position: relative;

    @media (max-width: ${media.tablet}) {
        width: 100vw;
        overflow-x: hidden;
    }
`;

export const IntroSection = () => {
    const { t } = useTranslation();
    const data = useStaticQuery(graphql`
        query introTickImage {
            tick: file(relativePath: { eq: "tickIcon.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        layout: FULL_WIDTH
                    )
                }
            }
        }
    `);

    const tickIcon = getImage(data.tick);

    return (
        <SIntroSection id="intro">
            <STextWrapper>
                <STextWrapperMotto>{t('index-page.motto')}</STextWrapperMotto>
                <STextWrapperHeader>{t('index-page.header')}</STextWrapperHeader>
                <STextWrapperDescription>{t('index-page.description')}</STextWrapperDescription>
                <Link href={`${APP_URL}/login`} title={t('index-page.login-button')} />
            </STextWrapper>
            <SImageWrapper>
                {tickIcon && (
                    <>
                        <Chip
                            icon={tickIcon}
                            alt={t('index-page.image.chip1.alt')}
                            boldText={t('index-page.image.chip1.boldText')}
                            text={t('index-page.image.chip1.text')}
                            boldTextSide="right"
                            absolute
                            top="291px"
                            right="283px"
                            topTablet="360px"
                        />
                        <Chip
                            icon={tickIcon}
                            alt={t('index-page.image.chip2.alt')}
                            text={t('index-page.image.chip2.text')}
                            boldText={t('index-page.image.chip2.boldText')}
                            absolute
                            top="200px"
                            right="-50px"
                            topTablet="520px"
                        />
                        <Chip
                            icon={tickIcon}
                            alt={t('index-page.image.chip3.alt')}
                            boldText={t('index-page.image.chip3.boldText')}
                            text={t('index-page.image.chip3.text')}
                            absolute
                            top="400px"
                            right="50px"
                            topTablet="440px"
                            rightTablet
                        />
                    </>
                )}
                <StaticImage
                    src="../../images/index-intro.png"
                    quality={95}
                    formats={['auto', 'webp', 'avif']}
                    alt="TURO planning"
                    style={{
                        zIndex: 60,
                        position: 'absolute',
                        height: 600,
                        width: 820,
                    }}
                />
            </SImageWrapper>
        </SIntroSection>
    );
};
