import colors from 'constants/styles/colors';
import { zIndex } from 'constants/styles/z-index';
import React from 'react';
import styled from 'styled-components';

const SHeader = styled.h2`
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0px;
    color: ${colors.textBold};
    margin-bottom: 24px;
    position: relative;

    ::before {
        position: absolute;
        content: '';
        width: calc(100% + 16px);
        height: 12px;
        background: ${colors.orange};
        bottom: 0px;
        z-index: ${zIndex.header};
        border-radius: 6px;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s ease;
    }
`;

interface Props {
    text: string;
}

export const Header = ({ text }: Props) => {
    return <SHeader>{text}</SHeader>;
};
