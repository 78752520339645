import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'gatsby-plugin-react-i18next';

import { StaticImage } from 'gatsby-plugin-image';

import colors from 'src/constants/styles/colors';
import media from 'constants/styles/media';

const SSection = styled.section`
    width: 100vw;
    height: 360px;
    display: grid;
    margin: 80px 0 40px;

    @media (max-width: ${media.tablet}) {
        margin: 100px 0 20px;
        min-height: 420px;
        height: auto;
    }
`;

const SBackgroundWrapper = styled.div`
    height: 100%;
    border-radius: 50px 0 0 50px;
    overflow: hidden;
    display: grid;
    grid-area: 1/1;

    @media (max-width: ${media.tablet}) {
        border-radius: 10px 0 0 10px;
    }
`;

const SContentWrapper = styled.div`
    height: 360px;
    border-radius: 50px 0 0 50px;
    padding: 40px 80px;
    display: flex;
    justify-content: flex-start;
    position: relative;
    grid-area: 1/1;

    @media (max-width: ${media.tablet}) {
        min-height: 420px;
        height: auto;
        flex-direction: column-reverse;
        justify-content: flex-end;
        padding: 165px 24px 45px;
        border-radius: 10px 0 0 10px;
    }
`;

const STextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    padding-top: 40px;

    @media (max-width: ${media.tablet}) {
        padding-top: 0px;
    }
`;

const SHeader = styled.h2`
    max-width: 370px;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0px;
    color: ${colors.white};
    margin-bottom: 20px;
`;

const SParagraph = styled.p`
    max-width: 467px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 24px;
    color: ${colors.text};
    margin-bottom: 40px;

    @media (max-width: ${media.tablet}) {
        max-width: 300px;
        margin-bottom: 0;
    }
`;

const SImageWrapper = styled.div`
    width: 650px;
    height: auto;
    position: relative;
    top: 0px;
    margin-left: 10px;

    @media (max-width: ${media.tablet}) {
        right: -70px;
        top: -140px;
        position: absolute;
        width: 450px;
    }

    @media (max-width: ${media.mini}) {
        width: 100%;
        top: -80px;
        right: -10px;
    }
`;

export const DemoSection = () => {
    const { t } = useTranslation();

    return (
        <SSection id="demo">
            <SBackgroundWrapper>
                <StaticImage
                    style={{
                        gridArea: '1/1',
                    }}
                    layout="fullWidth"
                    alt=""
                    src={'../../images/demo-background.png'}
                />
            </SBackgroundWrapper>
            <SContentWrapper>
                <STextWrapper>
                    <SHeader>{t('demo-section.header')}</SHeader>
                    <SParagraph>{t('demo-section.description')}</SParagraph>
                </STextWrapper>
                <SImageWrapper>
                    <StaticImage
                        src="../../images/demo-app-preview.png"
                        alt="Image showing a plan"
                        style={{
                            height: 'auto',
                            width: '100%',
                            overflow: 'visible',
                        }}
                    />
                </SImageWrapper>
            </SContentWrapper>
        </SSection>
    );
};
