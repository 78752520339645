import React from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';
import colors from 'src/constants/styles/colors';
import { GatsbyImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { useFooterContext } from 'utils/footer-context/index';
import { useTranslation } from 'react-i18next';

const SCardWrapper = styled.div`
    width: 340px;
    height: 380px;
    background: ${colors.white};
    border: 1px dashed ${colors.border};
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
`;

const SCardHeader = styled.h4`
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    color: ${colors.textBold};
    margin-bottom: 18px;
`;

const SCardDescription = styled.p`
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    color: ${colors.text};
    margin-bottom: 24px;
`;

const SCardAdvertisement = styled(AnchorLink)`
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #eebf4f;
`;

interface Props {
    header: string;
    description: string;
    advertisement: string;
    image: IGatsbyImageData;
    alt: string;
}

export const Card = ({ header, description, advertisement, image, alt }: Props) => {
    const { setTextAreaText } = useFooterContext();
    const { t } = useTranslation();

    const handleLinkClick = () => setTextAreaText(t('footer.text-from-anchor'));

    return (
        <SCardWrapper>
            <GatsbyImage
                image={image}
                alt={alt}
                style={{
                    width: 122,
                    height: 122,
                    marginBottom: 22,
                }}
            />
            <SCardHeader>{header}</SCardHeader>
            <SCardDescription>{description}</SCardDescription>
            <SCardAdvertisement to="/#footer" onAnchorLinkClick={handleLinkClick}>
                {advertisement}
            </SCardAdvertisement>
        </SCardWrapper>
    );
};
