import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { AboutPanel, TextSide } from './components/about-panel';

import media from 'constants/styles/media';
import { ImageBoxLayout } from './components/image-boxes';
import { StaticImage } from 'gatsby-plugin-image';
import { zIndex } from 'constants/styles/z-index';

const SSection = styled.section`
    width: 100%;
    position: relative;

    @media (max-width: ${media.desktop}) {
        padding-bottom: 70px;
    }

    @media (max-width: ${media.tablet}) {
        padding-bottom: 140px;
    }
`;

const SSphere = styled.div`
    position: absolute;
    top: -100px;
    left: -500px;
    z-index: ${zIndex.sphere};
    width: 998px;
    height: 570px;
    transform: matrix(0.98, 0.17, -0.17, 0.98, 0, 0);
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border-radius: 273px;
    opacity: 0.5;

    @media (max-width: ${media.tablet}) {
        top: -80px;
        left: -700px;
        height: 300px;
    }
`;

const SSecondSphere = styled.div`
    position: absolute;
    z-index: ${zIndex.sphere};
    top: 1350px;
    right: -200px;
    width: 977px;
    height: 366px;
    transform: matrix(0.98, 0.17, -0.17, 0.98, 0, 0);
    background: #f3f3f3 0% 0% no-repeat padding-box;
    border-radius: 273px;
    opacity: 0.5;

    @media (max-width: ${media.tablet}) {
        right: -650px;
        top: 1650px;
        height: 300px;
    }
`;

export const AboutSection = () => {
    const { t } = useTranslation();

    return (
        <SSection id="about">
            <SSphere />
            <SSecondSphere />
            <AboutPanel
                header={t('about-panel1.header')}
                description={t('about-panel1.description')}
                buttonTitle={t('about-panel1.button-title')}
                upperImage={
                    <StaticImage
                        layout="fullWidth"
                        quality={100}
                        objectFit="cover"
                        style={{ height: '100%' }}
                        alt="Tryb koordynacji - widok pomieszczenia"
                        src={'../../images/system/about-panel1-upper-image.png'}
                    />
                }
                lowerImage={
                    <StaticImage
                        layout="fullWidth"
                        objectFit="cover"
                        style={{ height: '100%' }}
                        alt="Tryb ogólny - kafelki z rysunkami technicznymi"
                        src={'../../images/system/about-panel1-lower-image.png'}
                    />
                }
            />
            <AboutPanel
                header={t('about-panel2.header')}
                description={t('about-panel2.description')}
                buttonTitle={t('about-panel2.button-title')}
                textSide={TextSide.Left}
                boxLayout={ImageBoxLayout.Version2}
                upperImage={
                    <StaticImage
                        layout="fullWidth"
                        quality={100}
                        objectFit="cover"
                        style={{ height: '100%' }}
                        alt="Lista rysunków"
                        src={'../../images/system/about-panel2-upper-image.png'}
                    />
                }
                lowerImage={
                    <StaticImage
                        layout="fullWidth"
                        quality={100}
                        objectFit="cover"
                        style={{ height: '100%' }}
                        alt="Widok z otwartym okienkiem szczegółów technicznych"
                        src={'../../images/system/about-panel2-lower-image.png'}
                    />
                }
            />
            <AboutPanel
                header={t('about-panel3.header')}
                description={t('about-panel3.description')}
                buttonTitle={t('about-panel3.button-title')}
                boxLayout={ImageBoxLayout.Version3}
                upperImage={
                    <StaticImage
                        layout="fullWidth"
                        quality={100}
                        objectFit="cover"
                        style={{ height: '100%' }}
                        alt="Zaznaczony obszar inwestycji na rysunku technicznym"
                        src={'../../images/system/about-panel3-upper-image.png'}
                    />
                }
                lowerImage={
                    <StaticImage
                        layout="fullWidth"
                        quality={100}
                        objectFit="cover"
                        style={{ height: '100%' }}
                        alt="Tryb koordynacji - dane techniczne"
                        src={'../../images/system/about-panel3-lower-image.png'}
                    />
                }
            />
        </SSection>
    );
};
