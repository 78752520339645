import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import colors from 'src/constants/styles/colors';
import { Chip } from 'components/chip';
import { useStaticQuery, graphql } from 'gatsby';
import media from 'constants/styles/media';
import { Header } from 'components/header';

const SSection = styled.section`
    height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: ${media.tablet}) {
        height: 850px;
    }
`;

const SParagraph = styled.p`
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: ${colors.text};
    opacity: 1;
    margin-bottom: 64px;
    padding: 0 20px;

    @media (max-width: ${media.tablet}) {
        width: 100%;
        margin-bottom: 32px;
        padding: 0;
    }
`;

const SLogoWrapper = styled.div`
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SChipsWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${media.tablet}) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const SRightChipWrapper = styled.div`
    @media (max-width: ${media.tablet}) {
        margin-top: 20px;
        margin-left: auto;
    }
`;

const SImageWrapper = styled.div`
    @media (max-width: ${media.tablet}) {
        width: 140%;
        margin: 20px 0;
    }
`;

export const AboutUsSection = () => {
    const { t } = useTranslation();
    const data = useStaticQuery(graphql`
        query tickImage {
            tick: file(relativePath: { eq: "tickIcon.png" }) {
                id
                childImageSharp {
                    gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        layout: FULL_WIDTH
                    )
                }
            }
        }
    `);

    const tickIcon = getImage(data.tick);

    return (
        <SSection id="about-us">
            <Header text={t('about-us.header')} />
            <SParagraph>{t('about-us.description')}</SParagraph>
            <SLogoWrapper>
                {tickIcon && (
                    <SChipsWrapper>
                        <Chip
                            text={t('about-us.chip1.text')}
                            boldText={t('about-us.chip1.boldText')}
                            boldTextSide="right"
                            icon={tickIcon}
                            alt={t('about-us.chip1.alt')}
                        />
                        <SRightChipWrapper>
                            <Chip
                                boldText={t('about-us.chip2.boldText')}
                                text={t('about-us.chip2.text')}
                                icon={tickIcon}
                                alt={t('about-us.chip2.alt')}
                            />
                        </SRightChipWrapper>
                    </SChipsWrapper>
                )}
                <SImageWrapper>
                    <StaticImage
                        src="../../images/about-us.svg"
                        alt={t('about-us.logo-alt')}
                        style={{
                            width: '100%',
                        }}
                    />
                </SImageWrapper>
                {tickIcon && (
                    <SChipsWrapper>
                        <Chip
                            boldText={t('about-us.chip3.boldText')}
                            text={t('about-us.chip3.text')}
                            icon={tickIcon}
                            alt={t('about-us.chip3.alt')}
                        />
                        <SRightChipWrapper>
                            <Chip
                                boldText={t('about-us.chip4.boldText')}
                                text={t('about-us.chip4.text')}
                                icon={tickIcon}
                                alt={t('about-us.chip4.alt')}
                            />
                        </SRightChipWrapper>
                    </SChipsWrapper>
                )}
            </SLogoWrapper>
        </SSection>
    );
};
