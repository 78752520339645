import React from 'react';

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import colors from 'src/constants/styles/colors';
import styled from 'styled-components';
import media from 'constants/styles/media';
import { zIndex } from 'constants/styles/z-index';

interface StyleProps {
    absolute?: boolean;
    top?: string;
    right?: string;
    topTablet?: string;
    rightTablet?: boolean;
}

const SWrapper = styled.div<StyleProps>`
    position: ${(p) => (p.absolute ? 'absolute' : 'inline-block')};
    top: ${(p) => (p.top ? p.top : 0)};
    right: ${(p) => (p.right ? p.right : 0)};
    z-index: ${zIndex.chip};
    width: 280px;
    height: 54px;
    background: ${colors.white};
    box-shadow: 0px 6px 18px #00000014;
    border-radius: 29px;
    opacity: 0.9;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 10px;

    @media (max-width: ${media.tablet}) {
        top: ${(p) => (p.topTablet ? p.topTablet : 0)};
        right: ${(p) => p.rightTablet && '40px'};
        left: ${(p) => !p.rightTablet && 0};
    }
`;

const SStrong = styled.strong`
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    color: ${colors.textBold};
`;

const SParagraph = styled.p`
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0px;
    color: ${colors.textBold};
`;

interface Props extends StyleProps {
    text: string;
    boldText: string;
    boldTextSide?: 'left' | 'right';
    icon: IGatsbyImageData;
    alt: string;
}

export const Chip = ({
    text,
    boldText,
    boldTextSide = 'left',
    icon,
    alt,
    absolute = false,
    top,
    right,
    topTablet,
    rightTablet = false,
}: Props) => {
    return (
        <SWrapper
            absolute={absolute}
            top={top}
            right={right}
            topTablet={topTablet}
            rightTablet={rightTablet}>
            <GatsbyImage
                image={icon}
                alt={alt}
                style={{
                    width: 34,
                    height: 34,
                    marginRight: 10,
                }}
            />
            {boldTextSide === 'left' ? (
                <>
                    <SStrong>{boldText}&nbsp;</SStrong> <SParagraph>{text}</SParagraph>
                </>
            ) : (
                <>
                    <SParagraph>{text}&nbsp;</SParagraph> <SStrong>{boldText}</SStrong>
                </>
            )}
        </SWrapper>
    );
};
