import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Button } from 'components/button';

import colors from 'constants/styles/colors';
import media from 'constants/styles/media';
import { useFooterContext } from 'utils/footer-context';
import { useTranslation } from 'react-i18next';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { ImageBoxes, ImageBoxLayout } from '../image-boxes';

export enum TextSide {
    Left = 'left',
    Right = 'right',
}

const SRightWrapper = styled.div`
    height: 400px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 240px;

    @media (max-width: ${media.desktop}) {
        height: 750px;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 90px;
    }

    @media (max-width: ${media.mini}) {
        height: 800px;
    }
`;

const SLeftWrapper = styled(SRightWrapper)`
    flex-direction: row-reverse;
    height: 400px;
    margin-bottom: 140px;

    @media (max-width: ${media.desktop}) {
        flex-direction: column;
        height: 600px;
    }

    @media (max-width: ${media.mini}) {
        height: 700px;
    }
`;

const SRightTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    padding: 50px 10px 0;

    @media (max-width: ${media.desktop}) {
        width: 100%;
        padding: 0;
    }
`;

const SLeftTextWrapper = styled(SRightTextWrapper)`
    align-items: flex-end;
    text-align: right;
`;

const SHeader = styled.h2`
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0px;
    color: ${colors.textBold};
    margin-bottom: 24px;
`;

const SParagraph = styled.p`
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: ${colors.text};
    margin-bottom: 48px;
    max-width: 395px;
    line-height: 1.5;
`;

const SRightBoxes = styled(ImageBoxes)`
    left: -40px;

    @media (max-width: ${media.desktop}) {
        left: 0;
    }
`;

const SLeftBoxes = styled(ImageBoxes)`
    right: -40px;

    @media (max-width: ${media.desktop}) {
        right: 0;
    }
`;

interface Props {
    header: string;
    description: string;
    buttonTitle: string;
    boxLayout?: ImageBoxLayout;
    textSide?: TextSide;
    upperImage?: ReactNode;
    lowerImage?: ReactNode;
}

export const AboutPanel = ({
    header,
    description,
    buttonTitle,
    textSide = TextSide.Right,
    boxLayout = ImageBoxLayout.Version1,
    upperImage,
    lowerImage,
}: Props) => {
    const isTextSideRight = textSide === TextSide.Right;
    const { setTextAreaText } = useFooterContext();
    const { t } = useTranslation();

    const AboutPanelWrapper = isTextSideRight ? SRightWrapper : SLeftWrapper;
    const TextWrapper = isTextSideRight ? SRightTextWrapper : SLeftTextWrapper;

    const handleButtonClick = () => setTextAreaText(t('footer.text-from-anchor'));

    return (
        <AboutPanelWrapper>
            {isTextSideRight ? (
                <SRightBoxes layout={boxLayout} lowerImage={lowerImage} upperImage={upperImage} />
            ) : (
                <SLeftBoxes layout={boxLayout} lowerImage={lowerImage} upperImage={upperImage} />
            )}
            <TextWrapper>
                <SHeader>{header}</SHeader>
                <SParagraph>{description}</SParagraph>
                <AnchorLink to="/#footer" onAnchorLinkClick={handleButtonClick}>
                    <Button title={buttonTitle} />
                </AnchorLink>
            </TextWrapper>
        </AboutPanelWrapper>
    );
};
