import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import colors from 'constants/styles/colors';
import { useStaticQuery, graphql } from 'gatsby';
import { Header } from 'components/header';

const SSection = styled.section`
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        height: 1400px;
        margin-bottom: 0px;
    }
`;

const SStepsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    @media (max-width: 768px) {
        align-items: center;
        flex-direction: column;
    }
`;

const SStepWrapper = styled.div`
    width: 150px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const SStepHeader = styled.h6`
    width: 212px;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0px;
    color: ${colors.textBold};
`;

const SDots = styled.div`
    border-top: dotted 2px ${colors.orange};
    min-width: 110px;
    height: 1px;
    min-height: unset;
    border-left: none;
    transform: translateY(50px);

    @media (max-width: 768px) {
        transform: translate(54px, 0px);
        border-top: none;
        width: 1px;
        min-height: 110px;
        border-left: 2px dotted ${colors.orange};
        margin: 10px 0;
    }
`;

export const ProcessSection = () => {
    const { t } = useTranslation();
    const { steps } = useStaticQuery(graphql`
        query stepIcons {
            steps: allFile(filter: { relativeDirectory: { eq: "process" } }) {
                nodes {
                    id
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                            layout: FULL_WIDTH
                        )
                    }
                }
            }
        }
    `);

    return (
        <SSection>
            <Header text={t('process.header')} />
            <SStepsWrapper>
                {steps.nodes.map((step: IGatsbyImageData, i: number) => {
                    const image = getImage(step);
                    if (image) {
                        return (
                            <>
                                <SStepWrapper>
                                    <GatsbyImage
                                        image={image}
                                        alt=""
                                        style={{
                                            width: 100,
                                            height: 100,
                                            marginBottom: 20,
                                        }}
                                    />
                                    <SStepHeader>{t(`process.step-${i + 1}`)}</SStepHeader>
                                </SStepWrapper>
                                {i + 1 < steps.nodes.length && <SDots />}
                            </>
                        );
                    }
                })}
            </SStepsWrapper>
        </SSection>
    );
};
