import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Card } from 'components/card';
import colors from 'src/constants/styles/colors';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import media from 'constants/styles/media';
import { Header } from 'components/header';

const SSection = styled.section`
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
`;

const SParagraph = styled.p`
    width: 100%;
    max-width: 1090px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    color: ${colors.text};
    margin-bottom: 58px;
`;

const SCardWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 36px;
    place-items: center;

    @media (max-width: ${media.tablet}) {
        grid-template-columns: 1fr;
    }
`;

export const WhatDoYouGetSection = () => {
    const { t } = useTranslation();
    const { icons } = useStaticQuery(graphql`
        query whatDoYouGet {
            icons: allFile(filter: { relativeDirectory: { eq: "what-do-you-get" } }) {
                nodes {
                    id
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                            layout: FULL_WIDTH
                        )
                    }
                }
            }
        }
    `);

    return (
        <SSection id="what-do-you-get">
            <Header text={t('what-do-you-get.header')} />
            <SParagraph>{t('what-do-you-get.description')}</SParagraph>
            <SCardWrapper>
                {icons.nodes.map((icon: IGatsbyImageData, i: number) => {
                    const image = getImage(icon);
                    if (image) {
                        return (
                            <Card
                                header={t(`what-do-you-get.card${i + 1}.header`)}
                                description={t(`what-do-you-get.card${i + 1}.description`)}
                                advertisement={t(`what-do-you-get.advertisement`)}
                                image={image}
                                alt=""
                                key={i}
                            />
                        );
                    }
                })}
            </SCardWrapper>
        </SSection>
    );
};
