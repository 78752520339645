import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import media from 'constants/styles/media';

const SSection = styled.section`
    width: 100%;
`;

const SDesktopWrapper = styled.div`
    display: grid;
    grid-area: 1/1;
    width: 100%;
    height: 100%;
    padding: 160px 0;

    @media (max-width: ${media.tablet}) {
        display: none;
    }
`;

const SMobileWrapper = styled.div`
    display: grid;
    grid-area: 1/1;
    width: 100%;
    height: 100%;
    padding: 110px 0;

    @media (min-width: ${media.tablet}) {
        display: none;
    }
`;

export const SystemFeatures = () => {
    const { t } = useTranslation();

    return (
        <SSection id="system-features">
            <SDesktopWrapper>
                <StaticImage
                    src="../../images/system-features.png"
                    alt={t('system-features.image-alt')}
                    style={{
                        gridArea: '1/1',
                    }}
                    layout="fullWidth"
                />
            </SDesktopWrapper>
            <SMobileWrapper>
                <StaticImage
                    src="../../images/system-features-mobile.png"
                    alt={t('system-features.image-alt')}
                    style={{
                        gridArea: '1/1',
                    }}
                    layout="fullWidth"
                />
            </SMobileWrapper>
        </SSection>
    );
};
