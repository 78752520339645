import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'src/layout';
import { Seo } from 'components/seo';
import { IndexView } from 'src/views/index-view';

import { createClient, Provider } from 'urql';
import { API_URL } from 'constants/index';

declare const window: Window & typeof globalThis & { printApplicationVersion: () => void };

const IndexPage = () => {
    const client = createClient({
        url: API_URL,
    });

    useEffect(() => {
        window.printApplicationVersion = () => {
            // eslint-disable-next-line no-console
            console.info({
                version: process.env.VERSION,
                buildTimestamp: new Date(process.env.BUILD_TIMESTAMP as string).toLocaleString(),
                rawBuildTimestamp: process.env.BUILD_TIMESTAMP,
            });
        };
    }, []);

    return (
        <Provider value={client}>
            <Layout>
                <Seo title="Home" />
                <IndexView />
            </Layout>
        </Provider>
    );
};

export default IndexPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
